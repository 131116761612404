import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel" }
const _hoisted_2 = { class: "icon is-medium clickAble right" }
const _hoisted_3 = { class: "panel-block form" }
const _hoisted_4 = { class: "field is-horizontal" }
const _hoisted_5 = { class: "field-body" }
const _hoisted_6 = {
  key: 0,
  id: "view-settings",
  class: "field"
}
const _hoisted_7 = { class: "control" }
const _hoisted_8 = { style: {"margin":"1.5em"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: "panel-heading",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isOpen = !_ctx.isOpen), ["stop"]))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.t("socialsupport")), 1),
      _createElementVNode("span", _hoisted_2, [
        (_ctx.isOpen)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: "chevron-up"
            }))
          : (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 1,
              icon: "chevron-down",
              size: "1x"
            }))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.t("introductionsentence")), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["support clickAble", { active: _ctx.filterEmotional }]),
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleEmotional && _ctx.toggleEmotional(...args)), ["stop"]))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "ico",
                        icon: "heart"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("emotional")) + ": " + _toDisplayString(_ctx.alteriEmotional), 1)
                    ], 2)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["support clickAble", { active: _ctx.filterInstrumental }]),
                      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleInstrumental && _ctx.toggleInstrumental(...args)), ["stop"]))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "ico",
                        icon: "toolbox"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("instrumental")) + ": " + _toDisplayString(_ctx.alteriMaterial), 1)
                    ], 2)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["support clickAble", { active: _ctx.filterInformational }]),
                      onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleInformational && _ctx.toggleInformational(...args)), ["stop"]))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "ico",
                        icon: "lightbulb"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("informational")) + ": " + _toDisplayString(_ctx.alteriCognitive), 1)
                    ], 2)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["support clickAble", { active: _ctx.filterSocial }]),
                      onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleSocial && _ctx.toggleSocial(...args)), ["stop"]))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "ico",
                        icon: "users"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("social")) + ": " + _toDisplayString(_ctx.alteriSocial), 1)
                    ], 2)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["support clickAble", { active: _ctx.filterLinking }]),
                      onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleLinking && _ctx.toggleLinking(...args)), ["stop"]))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "ico",
                        icon: "link"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("linking")) + ": " + _toDisplayString(_ctx.alteriLinking), 1)
                    ], 2)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}