
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useStore } from "@/store";

import NetworkMapCoordinates from "@/components/NetworkMapCoordinates.vue";
import NetworkMapSectors from "@/components/NetworkMapSectors.vue";
import ComparisonSlider from "@/components/ComparisonSlider.vue";

import * as d3 from "d3";
import { Alter, isConnectable } from "@/data/Alter";
import { shapeByGender } from "@/data/Gender";
import { TAB_BASE, TAB_CONNECTIONS } from "@/store/sessionModule";
import { SYMBOL_DECEASED } from "@/assets/utils";
import { getRoleAbbrev } from "../data/Roles";
import { brushSelection, D3BrushEvent } from "d3";
import { zoom, ZoomTransform, zoomIdentity } from "d3-zoom";
import de from "@/de";
import en from "@/en";

interface AlterMark {
  d: Alter;
  label: string;
  shape: string;
  x: number;
  y: number;
  selected: boolean;
}

interface ConnectionMark {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  selected: boolean;
}

// knows list of Alter from vuex
// knows editedAlter
// emit "map-click" (which is not currently used)

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  components: { NetworkMapCoordinates, NetworkMapSectors, ComparisonSlider },
  emits: ["map-click"],

  setup: function (props, { emit }) {
    const store = useStore();

    const isEditMode = computed(() => {
      return (
        store.state.session.editIndex != null &&
        store.state.session.editTab === TAB_BASE
      );
    });

    const getPositionPolar = (event: UIEvent) => {
      const coords = d3.pointer(event);

      // revert viewport projection
      const xa = transform.value.invertX(coords[0]);
      const ya = transform.value.invertY(coords[1]);

      // cp. https://stackoverflow.com/a/33043899/1140589
      const distance = Math.sqrt(xa * xa + ya * ya);
      const angle = Math.atan2(-1 * ya, xa) * (180 / Math.PI);

      return { distance, angle };
    };

    const isConnectMode = computed(
      () => store.state.session.editTab === TAB_CONNECTIONS
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const zoomBehavior: any = zoom();
    const transform = ref<ZoomTransform>(zoomIdentity);

    /**
     * brush selection in unzoomed coordinates
     */
    let brushAbsoluteCoords = [
      [0, 0],
      [0, 0],
    ] as [[number, number], [number, number]];

    onMounted(() => {
      // d3.mouse only works if the event is registered using D3 .on

      const svg = d3.select("#nwkmap");

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      zoomBehavior
        .extent([
          [-106, -106],
          [212, 212],
        ])
        .translateExtent([
          [-106, -106],
          [212, 212],
        ])
        .scaleExtent([1, 8])
        .on("zoom", zoomed);

      svg.call(zoomBehavior).on("mousedown.zoom", null);
      svg.on("dblclick.zoom", null);

      //Zoom with mouse wheel
      function zoomed(event: { transform: ZoomTransform }) {
        // console.log(event.transform);
        // inform vue's reactivity of updated zoom
        transform.value = event.transform;

        // re-scale brush selection
        const brushElement = d3.select("#nwkmap g#brush").node() as SVGGElement;
        if (brushElement && brushSelection(brushElement)) {
          const myExtent = [
            transform.value.apply(brushAbsoluteCoords[0]),
            transform.value.apply(brushAbsoluteCoords[1]),
          ] as d3.BrushSelection;
          brush.move(d3.select(brushElement), myExtent);
        }
      }

      const g = d3.select("#nwkmap");

      let clickBackgroundTimeoutId: number | null = null;
      g.on("click", (event) => {
        const posPol = getPositionPolar(event);
        if (clickBackgroundTimeoutId == null) {
          // first click -> start timer
          clickBackgroundTimeoutId = setTimeout(() => {
            // timeout expired -> simple click
            clickBackgroundTimeoutId = null;

            if (isEditMode.value) {
              const payload = {
                index: store.state.session.editIndex,
                changes: posPol,
              };
              store.commit("editAlter", payload);
              // } else {
              //   store.commit("session/clearSelectedAlters");
            }
            emit("map-click", posPol);
          }, 500); //tolerance in ms
        } else {
          // 2nd click -> double click
          clearTimeout(clickBackgroundTimeoutId);
          clickBackgroundTimeoutId = null;

          if (isEditMode.value) {
            store.commit("editAndCloseAlterForm", { changes: posPol });
          } else {
            store.commit("addAlter", posPol);
          }
          emit("map-click", posPol);
        }
      });

      initBrushBehavior();
    });

    // disable brush as long as in edit or connect mode
    watch(
      () => isEditMode.value || isConnectMode.value,
      (newIsSomeMode: boolean) => {
        if (newIsSomeMode) {
          d3.select("#nwkmap #brush").remove();
          if (brushBtns.value) {
            brushBtns.value.style.visibility = "hidden";
          }
        } else {
          initBrushBehavior();
        }
      }
    );

    const brush = d3.brush().extent([
      [-105, -105],
      [105, 105],
    ]);

    function initBrushBehavior() {
      brush
        .on("start", () => {
          // when brush is changed (e.g. resized) -> hide buttons
          if (brushBtns.value) {
            brushBtns.value.style.visibility = "hidden";
          }
        })
        .on("end", afterBrushChanged);

      d3.select("#nwkmap g.brushParent")
        .append("g")
        .attr("class", "brush")
        .attr("id", "brush")
        .call(brush);

      d3.select("#nwkmap")
        .select("#brush > .selection")
        .style("fill", "steelblue")
        .style("opacity", "0.4")
        .style("stroke-width", "0.5");
    }

    /** resets the brush to a null selection */
    const clearBrush = () => {
      if (brush) {
        brush.clear(d3.select("#nwkmap #brush"));
      }
    };

    let markIdsInExtent: number[] = [];
    let connectableIdsInExtent: number[] = [];

    // we need a DOM ref in order to focus
    const brushBtns = ref<InstanceType<typeof HTMLDivElement> | null>(null);

    const isClusterConnectPossible = ref(false);
    const isClusterFullyConnected = ref(false);

    function zoomBrushedArea() {
      const brushElement = d3.select("#brush").node() as SVGGElement | null;

      if (brushElement) {
        const extent = brushSelection(brushElement) as [
          [number, number],
          [number, number]
        ];

        if (extent) {
          const x0 = extent[0][0];
          const y0 = extent[0][1];
          const x1 = extent[1][0];
          const y1 = extent[1][1];

          const brushWidth = Math.abs(x1 - x0);
          const brushHeight = Math.abs(y1 - y0);

          const k =
            (212 / Math.max(brushWidth, brushHeight)) * transform.value.k;

          // brush center in absolute coordinates
          const tx = transform.value.invertX((x0 + x1) / 2);
          const ty = transform.value.invertY((y0 + y1) / 2);
          console.log(`k: ${k}  t.x: ${tx}   t.y: ${ty}`);

          const nextTransform = new ZoomTransform(k, tx * k * -1, ty * k * -1);
          d3.select("#nwkmap")
            .transition()
            .duration(750)
            .call(zoomBehavior.transform, nextTransform);

          // Clear the brush selection
          clearBrush();
        }
      }
    }

    function zoomSector(dirX: number, dirY: number) {
      const nextTransform = new ZoomTransform(1.9, -94.4 * dirX, -94.4 * dirY);
      d3.select("#nwkmap")
        .transition()
        .duration(750)
        .call(zoomBehavior.transform, nextTransform);
    }

    function afterBrushChanged(event: D3BrushEvent<unknown>) {
      // console.log(event);

      if (event.selection) {
        // brush changed can also result from zooming, but then it has no source event
        if (event.sourceEvent) {
          const extent = event.selection as [
            [number, number],
            [number, number]
          ];

          // remember the brush coordinates
          brushAbsoluteCoords = [
            transform.value.invert(extent[0]),
            transform.value.invert(extent[1]),
          ];

          const marksInExtent = filteredAlteriMarks.value.filter((am) =>
            isInBrushExtent(am, extent)
          );
          markIdsInExtent = marksInExtent.map((am) => am.d.id);
          store.commit("session/selectAlters", markIdsInExtent);

          // check if cluster connect is possible (more than 1 connectable alter)
          connectableIdsInExtent = marksInExtent
            .filter((am) => isConnectable(am.d))
            .map((am) => am.d.id);
          isClusterConnectPossible.value = connectableIdsInExtent.length >= 2;
          isClusterFullyConnected.value =
            isClusterConnectPossible.value &&
            clusterConnected(connectableIdsInExtent);
        }

        // move buttons relative to selection box as SVG element
        // <https://stackoverflow.com/questions/26049488/how-to-get-absolute-coordinates-of-object-inside-a-g-group>
        const divChart = document.querySelector("div#chart");
        const svgExtent = document.querySelector("#brush > .selection");
        if (divChart && svgExtent) {
          // console.log(svgExtent);
          const chartRect = divChart.getBoundingClientRect();
          const selRect = svgExtent.getBoundingClientRect();
          // console.log(chartRect);
          // console.log(selRect);
          if (brushBtns.value) {
            brushBtns.value.style.visibility = "visible";
            brushBtns.value.style.top = selRect.y - chartRect.y + "px";
            brushBtns.value.style.right =
              chartRect.right - selRect.x + 4 + "px";
          }
        } else {
          console.warn("Brush rect not found");
        }
      } else {
        // console.log("Brush selection inactive");
        if (store.state.session.selected.size > 0) {
          store.commit("session/clearSelectedAlters");
        }
        if (brushBtns.value) {
          brushBtns.value.style.visibility = "hidden";
        }
      }
    }

    function isInBrushExtent(
      mark: AlterMark,
      extent: [[number, number], [number, number]]
    ) {
      return (
        mark.x >= extent[0][0] &&
        mark.x <= extent[1][0] &&
        mark.y >= extent[0][1] &&
        mark.y <= extent[1][1]
      );
    }

    //functionality of Reset button for zoom
    function resetZoom(): void {
      const svg: HTMLElement | null = document.getElementById("nwkmap");

      if (svg) {
        d3.select(svg)
          .transition()
          .duration(750)
          .call(zoomBehavior.transform, d3.zoomIdentity);
      }
    }

    function clusterConnect() {
      store.commit("addClusterConnections", connectableIdsInExtent);
      isClusterFullyConnected.value = true;
    }

    function clusterDisconnect() {
      store.commit("removeClusterConnections", connectableIdsInExtent);
      isClusterFullyConnected.value = false;
    }

    function clusterConnected(markIds: number[]) {
      for (let i = 0; i < markIds.length; i++) {
        const connectedAlterIds = computed(() => {
          const myId = markIds[i];
          const id1s = store.state.nwk.connections
            .filter((d) => d.id2 == myId)
            .map((d) => d.id1);
          const id2s = store.state.nwk.connections
            .filter((d) => d.id1 == myId)
            .map((d) => d.id2);
          return [...id1s, ...id2s];
        });
        for (let x = i + 1; x < markIds.length; x++) {
          if (!connectedAlterIds.value.includes(markIds[x])) {
            return false;
          }
        }
      }
      return true;
    }

    const getRoleShort = (role: string) => {
      return getRoleAbbrev(role);
    };

    let clickTimeoutId: number | null = null;
    const clickAlter = (alter: Alter) => {
      if (isConnectMode.value && store.state.session.editIndex != null) {
        if (isConnectable(alter)) {
          const editId =
            store.state.nwk.alteri[store.state.session.editIndex].id;
          const payload = { id1: editId, id2: alter.id };
          store.commit("toggleConnection", payload);
        }
      } else {
        if (clickTimeoutId == null) {
          clickTimeoutId = setTimeout(() => {
            // simple click
            clickTimeoutId = null;
            console.log(alter.name + " clicked");

            // toggleSelection
            store.commit("session/selectSingleAlter", alter.id);
          }, 500); //tolerance in ms
        } else {
          // double click
          clearTimeout(clickTimeoutId);
          clickTimeoutId = null;
          console.log(alter.name + " dblclick");
          console.log("Text here");

          // open form
          store.commit("openAlterFormById", { alterId: alter.id });
        }
      }
    };

    const egoCoords = computed(() => transform.value.apply([0, 0]));

    /**
     * map of cartesian coords by alter.id (not the array index!)
     */
    const alteriCoords = computed((): Map<number, { x: number; y: number }> => {
      const buffer = new Map();

      store.state.nwk.alteri.forEach((alter) => {
        // calculate from polar coordinates
        const x = alter.distance * Math.cos((alter.angle * Math.PI) / 180);
        const y = -1 * alter.distance * Math.sin((alter.angle * Math.PI) / 180);

        // project to viewport using zoomBehaviour's transform
        const xp = transform.value.applyX(x);
        const yp = transform.value.applyY(y);

        buffer.set(alter.id, { x: xp, y: yp });
      });

      return buffer;
    });

    const alteriMarks = computed((): Array<AlterMark> => {
      // console.log("in computed alteri marks");
      const buffer: Array<AlterMark> = [];
      store.state.nwk.alteri.forEach((el) => {
        // console.log("alter: " + el.name);
        const coords = alteriCoords.value.get(el.id);

        buffer.push({
          d: el,
          label: store.getters["displayName"](el),
          shape: shapeByGender(el.human, el.currentGender),
          x: coords ? coords.x : 0,
          y: coords ? coords.y : 0,
          selected: store.getters["session/isSelected"](el.id),
        });
      });
      // first draw marks further away from center to avoid overplotting
      return buffer.sort((a, b) => b.d.distance - a.d.distance);
    });

    const connectionMarks = computed((): Array<ConnectionMark> => {
      return store.state.nwk.connections.map((conn) => {
        const coords1 = alteriCoords.value.get(conn.id1);
        const coords2 = alteriCoords.value.get(conn.id2);

        const selected =
          store.getters["session/isSelected"](conn.id1) ||
          store.getters["session/isSelected"](conn.id2);

        return {
          x1: coords1 ? coords1.x : 0,
          y1: coords1 ? coords1.y : 0,
          x2: coords2 ? coords2.x : 0,
          y2: coords2 ? coords2.y : 0,
          selected,
        };
      });
    });

    const noQualityFilter = computed(
      () =>
        !store.state.view.qualityRelationship ||
        (!store.state.session.filterEmotional &&
          !store.state.session.filterInstrumental &&
          !store.state.session.filterInformational &&
          !store.state.session.filterSocial &&
          !store.state.session.filterLinking)
    );

    const filteredAlteriMarks = computed(() =>
      alteriMarks.value.filter(
        (mark) =>
          noQualityFilter.value ||
          (store.state.session.filterEmotional &&
            mark.d.supportEmotional >= 1) ||
          (store.state.session.filterInstrumental &&
            mark.d.supportInstrumental >= 1) ||
          (store.state.session.filterInformational &&
            mark.d.supportInformational >= 1) ||
          (store.state.session.filterSocial && mark.d.supportSocial >= 1) ||
          (store.state.session.filterLinking && mark.d.supportLinking >= 1)
      )
    );

    const alteriSupportMarks = computed(() => {
      if (store.state.view.qualityRelationship) {
        return filteredAlteriMarks.value
          .filter((mark) => mark.d.edgeType >= 1)
          .map((mark) => {
            const arrowMe =
              mark.d.supportLinking == 1 ||
              mark.d.supportLinking == 3 ||
              mark.d.supportSocial == 1 ||
              mark.d.supportSocial == 3 ||
              mark.d.supportInstrumental == 1 ||
              mark.d.supportInstrumental == 3 ||
              mark.d.supportEmotional == 1 ||
              mark.d.supportEmotional == 3 ||
              mark.d.supportInformational == 1 ||
              mark.d.supportInformational == 3;
            const arrowAlter =
              mark.d.supportLinking >= 2 ||
              mark.d.supportSocial >= 2 ||
              mark.d.supportInstrumental >= 2 ||
              mark.d.supportEmotional >= 2 ||
              mark.d.supportInformational >= 2;

            const icons = [];
            if (mark.d.supportEmotional >= 1) icons.push("heart");
            if (mark.d.supportInstrumental >= 1) icons.push("toolbox");
            if (mark.d.supportInformational >= 1) icons.push("lightbulb");
            if (mark.d.supportSocial >= 1) icons.push("users");
            if (mark.d.supportLinking >= 1) icons.push("link");

            return { m: mark, arrowMe, arrowAlter, icons };
          });
      } else {
        return [];
      }
    });

    return {
      egoLabel: computed(
        () =>
          store.state.nwk.ego.name +
          (store.state.nwk.ego.age.length > 0
            ? " (" + store.state.nwk.ego.age + "a)"
            : "")
      ),
      egoShape: computed(() =>
        shapeByGender(true, store.state.nwk.ego.currentGender)
      ),
      noQualityFilter,
      egoEmoji: computed(() => store.state.nwk.ego.emoji),
      isEditMode,
      isConnectMode,
      clickAlter,
      transform,
      egoCoords,
      alteriMarks,
      filteredAlteriMarks,
      alteriSupportMarks,
      connectionMarks,
      labelSize: computed(() => store.state.view.labelSizeInNwk),
      iconSize: computed(() => store.state.view.iconSizeInNwk),
      iconTranslate: computed(
        () =>
          `translate(${store.state.view.iconSizeInNwk / -2},${
            store.state.view.iconSizeInNwk / -2
          })`
      ),
      showAge: computed(() => store.state.view.ageInNwk),
      showRole: computed(() => store.state.view.roleInNwk),
      getRoleShort,
      alteriNames: computed(() => store.state.view.alteriNames),
      connections: computed(() => store.state.view.connections),
      connectionsEgo: computed(() => store.state.view.connectionsEgo),
      emoji: computed(() => store.state.view.emoji),
      brushBtns,
      isClusterConnectPossible,
      isClusterFullyConnected,
      clusterConnect,
      clusterDisconnect,
      clearBrush,
      zoomSector,
      isNotZoomed: computed(() => transform.value.k == 1),
      resetZoom,
      zoomBrushedArea,
      SYMBOL_DECEASED,
      // TODO browser detection b/c vector-effect seems not to work in Safari only as of 14 Dec 2021
      useTextBG: computed(
        () =>
          !(
            /Safari/.test(navigator.userAgent) &&
            /Apple Computer/.test(navigator.vendor)
          )
      ),
      showQuality: computed(() => store.state.view.qualityRelationship),
      showVersionSlider: computed(() => store.state.record.versions.length > 1),
    };
  },
});
// x(distance: number, angle: number): number {
//     return distance * Math.cos(angle * Math.PI/180)
// }
