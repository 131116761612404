
import { defineComponent, ref, computed } from "vue";
import { useStore } from "@/store";
import de from "@/de";
import en from "@/en";
import { SupportFields } from "@/data/Alter";

export default defineComponent({
  mixins: [de, en],
  methods: {
    t(prop: string) {
      return this[document.documentElement.lang][prop];
    },
  },
  setup() {
    const store = useStore();

    const isOpen = ref(false);

    function listAlteriBySupport(field: keyof SupportFields) {
      return computed(() =>
        store.state.nwk.alteri
          .filter(
            (alter) =>
              alter[field] >= 1 && alter[field] <= 3 && alter.edgeType >= 1
          )
          .map((alter) => alter.name)
          .join(", ")
      );
    }

    return {
      isOpen: isOpen,

      filterEmotional: computed(() => store.state.session.filterEmotional),
      filterInstrumental: computed(
        () => store.state.session.filterInstrumental
      ),
      filterInformational: computed(
        () => store.state.session.filterInformational
      ),
      filterSocial: computed(() => store.state.session.filterSocial),
      filterLinking: computed(() => store.state.session.filterLinking),

      alteriEmotional: listAlteriBySupport("supportEmotional"),
      alteriMaterial: listAlteriBySupport("supportInstrumental"),
      alteriCognitive: listAlteriBySupport("supportInformational"),
      alteriSocial: listAlteriBySupport("supportSocial"),
      alteriLinking: listAlteriBySupport("supportLinking"),

      toggleEmotional: () => store.commit("session/toggle", "filterEmotional"),
      toggleInformational: () =>
        store.commit("session/toggle", "filterInformational"),
      toggleSocial: () => store.commit("session/toggle", "filterSocial"),
      toggleInstrumental: () =>
        store.commit("session/toggle", "filterInstrumental"),
      toggleLinking: () => store.commit("session/toggle", "filterLinking"),
    };
  },
});
